
'use strict';

var ebates = ebates || {}
ebates.helpers = ebates.helpers || {};
ebates.helpers.forms = ebates.helpers.forms || (function () {

    ///////////////////////////////////////
    // CONSTANTS
    ///////////////////////////////////////
    const errorColor = "#d8000d";
    const inpBorderColor = "#DDDDDD";
    const defaultAppOnlyWithStore = "default";
    const textIdMap = {
        'Ebates' : {
            'app-only' : 'aec96cc4-c4a2-4373-b649-281e2a97bd9d',
            'app-only-with-store': {
                [defaultAppOnlyWithStore]   : "aec96cc4-c4a2-4373-b649-281e2a97bd9d",
                "16269"/*uber*/             : "2a49b942-fe19-4a8f-bf3f-2180688d5b85",
                "15496"/*door dash*/        : "bc7a45b3-5d25-4fc3-9a97-4ecfefa1ab18",
                "14485"/*seat geek*/        : "238fe307-319a-4827-baed-1b7aa9fb13f9",
                "16663"/*rover*/            : "47aaf103-d85a-409e-9cf7-22a0dd849e97"
            },
            'mobile' : 'f2ce21d4-f8d6-4af0-9458-6e387af671ac'
        },
        'Rakuten': {
            'app-only' : 'c0f7eb33-6842-40a2-910a-6ac7ed120033',
            'app-only-with-store': {
                [defaultAppOnlyWithStore]   : "c0f7eb33-6842-40a2-910a-6ac7ed120033",
                "16269"/*uber*/             : "5d7c7818-1764-4cd2-b89a-1dc34dd7c5ed",
                "15496"/*door dash*/        : "ece01daa-575d-4bcf-a3fe-7d572541d78e",
                "14485"/*seat geek*/        : "e88ae271-d980-4258-af0a-dc4da2c7069f",
                "16663"/*rover*/            : "e334164d-f74d-4f64-9a8b-5895ba1a087d"
            },
            'mobile' : '151a84c4-d42e-4cbb-b957-03a644ca6f2e'
        }
    }
    const tooltipTemplate = '<div class="tooltip"></div>';
    const invalidMessage = 'Only valid on 10-digit US mobile phone numbers';
    const constTooltipClass = "w-300 tooltip-show";
    const tooltipPositionClasses = {
        top: "tooltip-bottom " + constTooltipClass,
        bottom: "tooltip-top " + constTooltipClass,
        left: "tooltip-right " + constTooltipClass,
        right: "tooltip-left " + constTooltipClass
    }
    const doneMessaging = {
        'sendStats' : 'Text sms link',
        'buttonText' : 'Sent!',
        'v2' : 'v2'
    };
    const failMessaging = {
        'tooltip': 'Invalid phone number. Please try again.'
    }
    const linktextingUrl = 'https://api.linktexting.com/sendLink';
    const branchLinkBase = "https://go.ebat.es/nSNXq5bJmS";


    ///////////////////////////////////////
    // PUBLIC API
    ///////////////////////////////////////
    return {
        registerForm : registerForm,
        getTextId : getTextId
    }


    ///////////////////////////////////////
    // Main Functions
    ///////////////////////////////////////

    /**
     * Registers a form with tooltips for texting a user a link to download the mobile app.
     *
     * @param {String} formId The id of the form the input and button is enclosed in.
     * @param {String} button The class or id of the button within the form.
     * @param {String} position Position of the tooltip (top | right | left | bottom)
     * @param {String} linkTextId The ID of the LinkText found in the LinkTexting app and textIdMap above.
     * @param {Object} entity Metadata describing which page of the app.
     * @param {String} tooltipContainer The class or id of the wrapper around the element where tooltips will be used as a reference.
     * @param {String} inlineErrorSelector The class or id of the error element for inline text.
     * @param {Object} linkTextingOptions An object to hold LinkTexting configuration.
     * @param {String} linkTextingOptions.append Text to append at the end of the text content.
     * @param {String} linkTextingOptions.replace Text to replace the entire text.
     * @param {Object} branchLinkOptions An map to hold values used to build the branch string.
     * @param {String} branchLinkOptions.sessionId Current session id.
     * @param {String} branchLinkOptions.merchantId Merchant id of store page.
     * @param {String} branchLinkOptions.specialId ID of promo/special that was clicked.
     */
    function registerForm({
        formId,
        button,
        position,
        linkTextId,
        entity,
        tooltipContainer,
        inlineErrorSelector,
        linkTextingOptions = {},
        branchLinkOptions = {}
    }) {
        const form = $(formId);
        const buttonEl = $(button, form);
        const inpEl = $('input', form);
        let tt = $('.tooltip', form);
        const errorElem = $(inlineErrorSelector);
        const ttContainer = $(tooltipContainer);
        const { append, replace } = linkTextingOptions; //todo: remove when EB-26237 is complete
        const { sessionId, merchantId, specialId } = branchLinkOptions;

        ttContainer && ttContainer.css({position: "relative"});

        const linktextId = linkTextId;
        const v2Entities = [{
            entityType: entity.location,
            instanceId: "",
            dynamicEntityType: "",
            dynamicEntry: 'SMS: ' + entity.location
        }];
        const v2Data = {
            data: ["send", "event", entity.location, "Signed In",'SMS: ' + entity.location],
            entities: v2Entities
        }
        const v1Data = {
            data: ["send", "event", "SMS", location.pathname]
        }

        form.on("submit", handleSubmit);
        buttonEl.on("click", handleButton);
        inpEl.on("input propertychange", handleInputPropertyChange);
        window.addEventListener('DOMContentLoaded', sendPageView);
        $(document).on('click', 'a.mobile-ui', clickAppIcon);


        ///////////////////////////////////////
        // Callback Functions
        ///////////////////////////////////////
        function submitPost({url, payload}) {
            return jQuery.post(url, payload);
        }

        function onDone () {
            sendStats({ ea: doneMessaging.sendStats });
            buttonEl.text(doneMessaging.buttonText);
            inpEl.val("");
            form.hasClass(doneMessaging.v2) ? $(document).trigger("stats", v2Data) : $(document).trigger("stats", v1Data);
        }

        function onFail (evt) {
            errorElem && errorElem.removeClass('hide').text(failMessaging.tooltip);
            inpEl && inpEl.css("border-color", errorColor);
            ttContainer && toolTip(failMessaging.tooltip, position);
        }

        function validateNumber (noToolTip) {
            const val = inpEl.val();
            const number = val.replace(/[\D|\W]/g,'');
            if (!number || number.length !== 10) {
                !noToolTip && errorElem && errorElem.removeClass("hide").text(message).css('color', errorColor);
                !noToolTip && inpEl && inpEl.css("border-color", errorColor);
                !noToolTip && tooltipContainer && toolTip(message, position);
                return false;
            }
            return number;
        };

        function sendPageView () {
            sendStats({ea: "pageview"});
        }

        function clickAppIcon () {
            sendStats({ea: "Click app icon"});
        }

        function handleSubmit (evt) {
            evt.preventDefault();

            try {
                tt && tt.remove();
                var n = validateNumber();
                var phoneNumber = '1' + n;
                // let encodedDeeplinkPath = "us/store/";
                // encodedDeeplinkPath = merchantId ? `${encodedDeeplinkPath}${merchantId}/shop` : encodedDeeplinkPath;
                // encodedDeeplinkPath = specialId ? `${encodedDeeplinkPath}?spe=${specialId}` : encodedDeeplinkPath;
                // encodedDeeplinkPath = encodeURI(encodedDeeplinkPath);

                // let branchLink = branchLinkBase;
                //     branchLink = sessionId ? `${branchLink}?eb_web_session_id=${sessionId}` : branchLink;
                //     branchLink = merchantId ? `${branchLink}&$deeplink_path=${encodedDeeplinkPath}` : branchLink;

                const payload = {
                    linkId: linktextId,
                    number: phoneNumber,
                    // branchLink
                }

                n &&
                submitPost({url: linktextingUrl, payload})
                .done(onDone)
                .fail(onFail);

                return false;
            } catch(e) {
                console.error("---- error sending text message:", e);
            }
        }

        function handleButton (evt) {
            form.trigger("submit");
            evt.preventDefault();
        }

        function handleInputPropertyChange () {
            var t = startSetTimeout();


            if (inpEl && inpEl.css('border-color') !== inpBorderColor) {
                inpEl.css('border-color', inpBorderColor);
            }

            ttFadeOutRemove()
            removeInlineError()

            form.hasClass('v2') ? buttonEl.text("Send Link") : buttonEl.text("Send");
            form.removeClass("good error");
            t && clearTimeout(t);

            function startSetTimeout () {
                setTimeout(function() {
                    validateNumber(true) ? form.addClass("good"): form.addClass("error");
                }, 500);
            }

            return false;
        }

        function sendStats (data) {
            data.ec = entity.type;
            data.et = entity.name;
            data.el = entity.storeName;

            $(document).trigger('stats', { map: { external: data } });
        }

        function message() {
            return invalidMessage;
        }

        function toolTip (m, position) {
            tt = $(tooltipTemplate).addClass(tooltipPositionClasses[position]).appendTo(ttContainer).text(m);
            setTimeout(function () {
                ttFadeOutRemove();
            }, 5000);
            return false;
        };

        function removeInlineError() {
            errorElem && !errorElem.hasClass('hide') && errorElem.addClass("hide");
        }

        function ttFadeOutRemove () {
            tt.fadeOut(300, function () {
                tt.remove();
            });
        }
    }

    /**
     * Retrieves the corresponding LinkTextID as defined in the textIdMap constant above.
     *
     * @param {String} textIdType The type of text message defined in the textIdMap constant above.
     * @param {String} [storeId] The ID of the merchant.
     */
    function getTextId(textIdType, storeId = null) {
        const currentBrandName = ebates.rakutenmode.currentBrandName
        let res = textIdMap[currentBrandName][textIdType];
        // handles app-only-with-store
        if (storeId) {
            res = typeof res[storeId] === "string" ? res[storeId] : res[defaultAppOnlyWithStore];
        }
        return res;
    }
})();